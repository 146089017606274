import { CssVarsPonyfill } from "css-vars-ponyfill-react";
import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { ErrorBoundary, Spinner } from "./components";
import { Footer, Header } from "./containers";
import { Routing } from "./routes";
import { store } from "./store";
import { AxiosInterceptorsSetup } from "./utils";

export const App = () => {
  const [storeInitialState, setStoreInitialState] = useState({
    isLoading: false,
    clientName: "",
    isExternal: false,
    clientID: -1,
    iconList: []
  });

  const setManifestURL = useCallback(() => {
    if (storeInitialState.clientID !== -1) {
      console.log("storeInitialState.clientID: ", storeInitialState.clientID);

      const manifestURL = `/manifests/${storeInitialState.clientName?.toLocaleLowerCase()}/manifest.json`;
      const manifestEl: any = document.getElementById("manifest-pwa");

      fetch(`${manifestURL}`)
        .then((response) => {
          if (!response.ok) throw Error("JSON Not Found");
          return response.json();
        })
        .then((data) => {
          setStoreInitialState((prevState) => ({
            ...prevState,
            iconList: data.icons
          }));

          manifestEl.setAttribute("href", manifestURL);
        })
        .catch((error) => {
          console.log("ERROR Loading Manifest: ", error);
          setStoreInitialState((prevState) => ({
            ...prevState,
            iconList: []
          }));
          manifestEl && manifestEl.removeAttribute("href");
        });
    }
  }, [storeInitialState.clientID, storeInitialState.clientName]);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      if (
        storeInitialState.clientName !== store.getState().common.clientDetails.WebsiteType &&
        !(Object.keys(store.getState().common.clientDetails).length === 0 && store.getState().common.clientDetails === Object)
      ) {
        const clientDetails = store.getState()?.common?.clientDetails;
        setStoreInitialState((prevState) => ({
          ...prevState,
          isLoading: true,
          clientName: clientDetails.WebsiteType,
          isExternal: clientDetails.ClientOverrideRequired,
          clientID: clientDetails.ClientID
        }));
      }
      if (storeInitialState.clientID === store.getState()?.common?.clientDetails) {
        setManifestURL();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [setManifestURL, storeInitialState.clientID, storeInitialState.clientName]);

  const clientName = storeInitialState.clientName
    ? storeInitialState.clientName === "GWL"
      ? "canadalife"
      : storeInitialState.clientName === "Standard" && storeInitialState.isExternal
      ? "telus"
      : storeInitialState.clientName
    : "";

  document.body.className = `ml-layout${clientName?.toLowerCase()}`;

  if (clientName !== "") {
    CssVarsPonyfill({
      externalStyleSheets: true,
      onError: (err: any) => {
        console.log("Error processing stylesheet : ", err);
      }
    });
  }

  const appleTouchIcons: JSX.Element[] = [];
  storeInitialState.iconList.forEach((icon: { sizes: string | undefined; src: string | undefined }) =>
    appleTouchIcons.push(<link key={`apple-icon-${icon?.sizes}-ID`} rel="apple-touch-icon" sizes={icon?.sizes} href={icon?.src} />)
  );

  const faviconURL = storeInitialState.clientName ? `/assets/favicons/${storeInitialState.clientName?.toLowerCase()}/favicon.ico` : "/favicon.ico";

  const AxiosInterceptorNavigate = () => {
    const navigate = useNavigate();
    AxiosInterceptorsSetup(navigate);
    return null;
  };

  return (
    <>
      <Provider store={store}>
        <div className={`App`}>
          <Helmet>
            <link rel="shortcut icon" href={faviconURL} />
            {appleTouchIcons}
          </Helmet>
          <Router basename={"/"}>
            <Spinner />
            <AxiosInterceptorNavigate />
            <div className="page-container">
              <div className="content-wrap">
                {isMobile ? (
                  <>
                    <ErrorBoundary>
                      {storeInitialState.isLoading && <Header />}
                      <Routing />
                    </ErrorBoundary>
                  </>
                ) : (
                  <ErrorBoundary>
                    {storeInitialState.isLoading && <Header />}
                    <Routing />
                  </ErrorBoundary>
                )}
              </div>
              <Footer />
            </div>
          </Router>
        </div>
      </Provider>
    </>
  );
};
export default App;
